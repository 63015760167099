bazis-button {
    --height: 36px;
    --height-xs: 24px;
    --height-small: 32px;
    --height-large: 44px;

    --border-radius: var(--bazis-border-radius);
    --padding-top: 10px;
    --padding-bottom: 10px;
    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-4x);

    --padding-top-xs: 6px;
    --padding-bottom-xs: 6px;
    --padding-start-xs: 6px;
    --padding-end-xs: 6px;

    --padding-top-small: var(--bazis-padding-2x);
    --padding-bottom-small: var(--bazis-padding-2x);
    --padding-start-small: var(--bazis-padding-3x);
    --padding-end-small: var(--bazis-padding-3x);

    --padding-top-large: var(--bazis-padding-3x);
    --padding-bottom-large: var(--bazis-padding-3x);
    --padding-start-large: var(--bazis-padding-5x);
    --padding-end-large: var(--bazis-padding-5x);

    --transition: background-color 15ms linear, outline-color 15ms linear;

    --outline-width: 4px;
    --outline-style: solid;
    --outline-color: transparent;

    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.25px;

    &::part(inner) {
        line-height: 2;
    }

    bazis-icon.bazis-icon-xl[slot='icon-only'] {
        --bazis-icon-xl-size: 24px;
        margin-left: -10px;
        margin-right: -10px;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        bazis-icon.bazis-icon-xl[slot='icon-only'] {
            margin-left: unset;
            margin-right: unset;
            -webkit-margin-start: -10px;
            margin-inline-start: -10px;
            -webkit-margin-end: -10px;
            margin-inline-end: -10px;
        }
    }
}

.bazis-btn_solid {
    --background: var(--bazis-border-color);
    --color: var(--bazis-text-color);

    --background-hover: var(--bazis-color-info-shade);
    --background-activated: var(--bazis-color-info-tint);
    --background-focused: var(--background);
    --background-disabled: var(--bazis-text-color-secondary);
    --color-disabled: var(--bazis-text-color-contrast);
}

.bazis-btn_outline {
    --background: var(--bazis-color-white);
    --background-hover: var(--bazis-background-medium);
    --background-activated: var(--bazis-color-info-tint);
    --background-disabled: var(--bazis-background-dark);

    --border-color: var(--bazis-border-color-dark);
    --border-color-hover: var(--bazis-text-color-secondary);
    --border-color-focused: transparent;
    --border-color-disabled: var(--bazis-background-dark);

    --color: var(--bazis-text-color-secondary);
    --color-disabled: var(--bazis-text-color-secondary);

    bazis-icon[slot='icon-only'] {
        margin-left: -7px;
        margin-right: -7px;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        bazis-icon[slot='icon-only'] {
            margin-left: unset;
            margin-right: unset;
            -webkit-margin-start: -7px;
            margin-inline-start: -7px;
            -webkit-margin-end: -7px;
            margin-inline-end: -7px;
        }
    }
}

.bazis-btn_clear {
    --background-hover: var(--bazis-background-medium);
    --background-activated: var(--bazis-color-info-tint);
    --background-focused: var(--bazis-background-medium);

    --color: var(--bazis-text-color-secondary);
    --color-hover: var(--color);
    --color-focused: var(--color);
    --color-activated: var(--color);
}

.bazis-btn_large {
    font-size: 14px;
    letter-spacing: 0.5px;
}

.bazis-btn_small {
    bazis-icon[slot='icon-only'] {
        margin-left: calc(var(--bazis-margin-1x) * -1);
        margin-right: calc(var(--bazis-margin-1x) * -1);
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        bazis-icon[slot='icon-only'] {
            margin-left: unset;
            margin-right: unset;
            -webkit-margin-start: calc(var(--bazis-margin-1x) * -1);
            margin-inline-start: calc(var(--bazis-margin-1x) * -1);
            -webkit-margin-end: calc(var(--bazis-margin-1x) * -1);
            margin-inline-end: calc(var(--bazis-margin-1x) * -1);
        }
    }

    &.bazis-btn_outline {
        bazis-icon[slot='icon-only'] {
            margin-left: -5px;
            margin-right: -5px;
        }
        @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
            bazis-icon[slot='icon-only'] {
                margin-left: unset;
                margin-right: unset;
                -webkit-margin-start: -5px;
                margin-inline-start: -5px;
                -webkit-margin-end: -5px;
                margin-inline-end: -5px;
            }
        }
    }
}

.bazis-btn_xs {
    bazis-icon[slot='icon-only'] {
        margin-left: 0;
        margin-right: 0;
        font-size: 12px;

        &.bazis-icon-xs {
            margin-left: -2px;
            margin-right: -2px;
        }
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        bazis-icon[slot='icon-only'] {
            margin-left: unset;
            margin-right: unset;
            -webkit-margin-start: 0;
            margin-inline-start: 0;
            -webkit-margin-end: 0;
            margin-inline-end: 0;

            &.bazis-icon-xs {
                -webkit-margin-start: -2px;
                margin-inline-start: -2px;
                -webkit-margin-end: -2px;
                margin-inline-end: -2px;
            }
        }
    }
}

.bazis-btn_xs.bazis-btn_outline {
    bazis-icon[slot='icon-only'] {
        margin-left: -1px;
        margin-right: -1px;
    }
    @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
        bazis-icon[slot='icon-only'] {
            margin-left: unset;
            margin-right: unset;
            -webkit-margin-start: -1px;
            margin-inline-start: -1px;
            -webkit-margin-end: -1px;
            margin-inline-end: -1px;
        }
    }
}
