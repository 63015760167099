
.bazis-chat-list-wrapper {
    height: 100%;
    width: var(--width-left-col);
    flex: 0 0 var(--width-left-col);
    border-right: 1px solid var(--bazis-border-color);

    will-change: width, flex;
    transition: width 200ms linear, flex 200ms linear;

    .bazis-chats--collapsed & {
        width: var(--width-left-col-collapsed);
        flex: 0 0 var(--width-left-col-collapsed);
    }
}

.bazis-list_chat {
    --padding-start: var(--bazis-padding-4x);
    --padding-end: var(--bazis-padding-4x);
    --padding-top: var(--bazis-padding-3x);
    --padding-bottom: var(--bazis-padding-3x);
    --border-width: 0 0 1px;
    --border-color: var(--bazis-border-color);
    --border-style: solid;
    --border-radius: 0;

    overflow: auto;
    height: calc(100% - var(--height-header) - var(--height-back) - 1px);

    .bazis-item {
        --background-hover: var(--bazis-background-medium);
        --background-selected: var(--bazis-color-primary);
        --color-selected: rgba(var(--bazis-color-primary-contrast-rgb), 1);

        cursor: pointer;
        overflow: hidden;
        max-height: 85px;

        bazis-toolbar ~ * {
            margin-top: var(--bazis-margin-1x);
            line-height: 16px;
            white-space: nowrap;
        }

        bazis-badge {
            --margin-start: 0;
            --margin-end: 0
            --font-size-medium: 10px;
            min-width: 16px;
        }

        .bazis-chat__title {
            white-space: nowrap;
        }

        .bazis-chat__text *:not(.bazis-chat__emoticon, a, br) {
            display: contents;
            &::after {
                content: '\00a0';
            }
        }

        a {
            display: contents;
            pointer-events: none;
            text-decoration: none;
        }

        .bazis-avatar {
            --width: 48px;
            --height: 48px;
            --font-size: 13px;
            --background: var(--bazis-color-action);
            --color: var(--bazis-color-action-contrast);
            position: relative;
            letter-spacing: 0.25px;
            font-weight: 500;

            bazis-badge {
                position: absolute;
                bottom: 0;
                right: 0;
            }
        }


        &:hover:active {
            .bazis-chat__text,
            .bazis-chat__date {
                color: var(--bazis-text-color-secondary);
            }
        }

        &--selected,
        &--selected:hover,
        &--selected:hover:active {

            .bazis-chat__text,
            .bazis-chat__date {
                color: rgba(var(--bazis-color-primary-contrast-rgb), .8);
            }

            a {
                // display: inline-block;
                color: rgba(var(--bazis-color-primary-contrast-rgb), .8);
                // text-decoration: underline;
            }

            .bazis-avatar {
                --background: var(--bazis-color-action-contrast);
                --color: var(--bazis-color-action);
            }
        }

    }

    .bazis-chats--collapsed & {
        --padding-top: calc(var(--bazis-padding-4x) + 2px);
        --padding-bottom: calc(var(--bazis-padding-4x) + 2px);
    }
}
