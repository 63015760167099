.bazis-chat__message {
    display: flex;
    gap: var(--bazis-margin-4x);
    position: relative;
    margin: var(--bazis-margin-3x) 0;
    padding: var(--bazis-padding-2x);
    border-radius: var(--bazis-border-radius);

    &__body {
        width: calc(100% - (32px + 16px)); // 100% минус колонка с аватаром, минус gap

        bazis-toolbar ~ *:not(.bazis-chat__reactions, .bazis-chat__message__cite) {
            display: block;
            margin-top: var(--bazis-margin-2x);
        }

        .bazis-file-inline {
            .bazis-list .bazis-item:last-child & {
                margin-bottom: 0;
            }
        }
    }

    &__cite {
        display: flex;
        gap: var(--bazis-padding-4x);
        border-left: 2px solid var(--bazis-text-color);
        margin-top: var(--bazis-margin-2x);
        padding-left: calc(var(--bazis-padding-4x) - 2px);
        font-style: normal;

        .bazis-chat__reply__text {
            // margin-top: var(--bazis-margin-2x);

            .bazis-chat__text {
                width: 100%;
            }
        }

        .bazis-figure {
            --width: 40px;
            --height: 40px;
            --background: rgba(var(--bazis-color-action-rgb), 0.5);
            margin: 0;
        }
    }

    &__note {
        font-size: 11px;
        line-height: 12px;
        color: var(--bazis-text-color-secondary);
    }

    bazis-message:first-child & .bazis-dropdown:not(.bazis-dropdown_bottom) {
        --top: auto;
        --bottom: 28px;
    }

    .bazis-btn_clear.bazis-btn_small.bazis-btn_has-icon-only {
        // --background: var(--bazis-background);
        --background-hover: var(--bazis-color-info-tint);
    }

    .bazis-avatar {
        letter-spacing: 0.25px;
    }

    &__reactions {
        position: relative;
        margin-bottom: var(--bazis-margin-4x);

        .bazis-btn_xs {
            position: absolute;
            top: var(--bazis-padding-3x);
            right: var(--bazis-padding-4x);
        }
    }

    // &:hover {
    //     background: var(--bazis-background-medium);
    // }

    &--highlighting {
        animation-name: highlighting;
        animation-duration: 1000ms;
        animation-iteration-count: 1;
        animation-timing-function: ease-in-out;
    }
    &--contextmenu {
        background-color: var(--bazis-background-accent);
    }
}

@keyframes highlighting {
    from {
        background-color: transparent;
    }
    50% {
        background-color: var(--bazis-background-accent);
    }
    to {
        background-color: transparent;
    }
}
