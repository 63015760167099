bazis-toolbar,
.bazis-collapsible-to-more,
.bazis-list-page__tools {
    bazis-select,
    bazis-sort {
        .bazis-control {
            max-width: 205px;

            app-reservations & {
                max-width: 100%;
            }
        }
    }

    bazis-select .bazis-control,
    bazis-sort .bazis-control,
    bazis-select-during-date .bazis-select-during-date-control {
        --margin-bottom: 0;
        --border-width: 0;
        --background: var(--bazis-color-info-tint);
        --font-size: 14px;
        --color: var(--bazis-color-action);

        .bazis-control__field-group {
            &__end {
                button {
                    border: none;
                    min-width: var(--height);
                }
            }

            bazis-icon {
                color: var(--color);
            }

            .bazis-item--selected bazis-icon {
                color: var(--color-selected);
            }
        }
    }

    @media (max-width: 1024px) {
        bazis-select .bazis-control,
        bazis-sort .bazis-control,
        bazis-select-during-date .bazis-select-during-date-control,
        .sl-dropdown_settings-filter bazis-button.bazis-dropdown__trigger,
        .bazis-select_secondary {
            // TODO: border оставлен только из-за смены фона в адаптиве.
            --border-width: 1px;
            --border-style: solid;
            --border-color: var(--bazis-border-color-dark);
            --border-color-hover: var(--bazis-text-color-secondary);
        }

        // bazis-select .bazis-control,
        // bazis-sort .bazis-control {
        //     .bazis-control__field-group__end:not(
        //             .sl-toolbar_searchable .bazis-control__field-group__end
        //         ) {
        //         display: none;
        //     }
        // }
    }

    @media (max-width: 576px) {
        bazis-select,
        bazis-sort {
            &,
            .bazis-control {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.sl-toolbar_table,
.bazis-collapsible-to-more,
.bazis-list-page__tools {
    bazis-input-default.sl-search-control {
        width: calc(var(--max-width-container) * 0.65);
    }

    .sl-action-button {
        --background: var(--bazis-color-info-tint);
        --color: var(--bazis-color-base);
    }

    .bazis-control:not(bazis-sort .bazis-control) {
        --height: 30px;
        --margin-bottom: 0;

        .sl-form & {
            --margin-bottom: 0;
        }

        bazis-button.bazis-btn_clear:not(
            bazis-button-date .bazis-control__field-group__start bazis-button.bazis-btn_clear
        ) {
            --height: 30px;
        }
    }

    bazis-sort .bazis-control {
        --height: 32px;
    }

    @media (max-width: 1024px) {
        bazis-select .bazis-control,
        bazis-sort .bazis-control {
            max-width: 100%;
        }
    }
}

.sl-toolbar_searchable {
    bazis-select .bazis-control,
    bazis-sort .bazis-control {
        --height: 36px;
        width: var(--width-other-control);
    }
}
