.sl-main-page {
    --header-height: 56px;
    --bazis-background-medium: #f7f7f7;
    background-color: var(--bazis-background-medium);
    // position: relative;
    // top: calc(var(--header-height) * -1);
    // margin-bottom: calc(var(--header-height) * -1);

    .sl-section {
        --margin-top: 0;

        &__content {
            --padding-start: 40px;
            --padding-end: 40px;

            @media (max-width: 1024px) {
                --padding-start: 0;
                --padding-end: 0;

                .bazis-slider {
                    margin: 0 calc(var(--bazis-margin-5x) * -1);

                    &__content {
                        padding: var(--bazis-padding-5x);
                        gap: var(--bazis-margin-3x);
                    }
                }
            }

            @media (max-width: 576px) {
                // --padding-start: 0;
                // --padding-end: 0;

                h2 {
                    font-size: 32px;
                    line-height: 40px;
                }
            }

            @media (hover: none) and (pointer: coarse) {
                .bazis-slider {
                    &__controls {
                        display: none;
                    }
                    &__indicator {
                        display: flex;
                    }
                }
            }

            @media (hover: hover) and (pointer: fine) {
                .bazis-slider {
                    &__controls {
                        display: block;
                    }
                    &__indicator {
                        display: none;
                    }
                }
            }
        }

        // 1 section
        &_banner {
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                height: 90px;
                background: linear-gradient(
                    180deg,
                    #08101c 0%,
                    #08151b 0.01%,
                    rgba(8, 33, 59, 0) 100%
                );
            }

            .bazis-container {
                position: relative;
                background: linear-gradient(70deg, #07151a, #062443);

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    // left: -100%;
                    right: 100%;
                    background: linear-gradient(15deg, #07151a, #08171e);
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    // left: calc(100% + 275px);
                    left: calc(100% - 1px);
                    // right: -100%;
                    background: linear-gradient(-15deg, #072240, #072240 274px, #062443);
                }
            }

            video,
            .sl-video-poster {
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: calc(50% - 120px);
                transform: translateX(-50%);
                right: auto;
                width: auto;
                height: 100%;
                max-width: initial;
                // background: url('/assets/images/cover-banner.jpg') no-repeat center center;
                // background-size: cover;
            }

            .sl-section__content {
                --padding-top: 151px;
                --padding-bottom: 108px;
                --padding-start: 0;

                position: relative;
                z-index: 1;
                max-width: 620px; // 50% - 10px
                color: var(--bazis-text-color-contrast);

                h1 {
                    margin-bottom: 40px;
                    padding-bottom: 0;

                    + p {
                        font-size: 18px;
                        line-height: 28px;
                        margin-bottom: 40px;
                        opacity: 0.7;
                    }
                }
            }

            .sl-updates {
                --margin-bottom-list: 40px;
                align-items: baseline;

                .bazis-item:last-child {
                    display: inline-flex;
                    align-items: baseline;

                    &::before {
                        position: relative;
                        top: -2px;
                    }

                    span {
                        position: relative;
                        top: 1px;
                    }
                }
            }

            bazis-button {
                --height: 48px;
                font-size: 16px;
            }

            @media (max-width: 1280px) and (min-width: 1025px) {
                video,
                .sl-video-poster {
                    left: calc(50% - 250px);
                }
            }

            @media (max-width: 1024px) {
                video,
                .sl-video-poster {
                    display: none;
                }

                .sl-section__content {
                    --padding-top: 128px;
                    --padding-bottom: 80px;
                }
                h1 {
                    font-weight: 400;
                    font-size: 45px;
                    line-height: 52px;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 104px;
                    --padding-bottom: 72px;
                    width: 100%;
                }
                .sl-updates {
                    display: block;

                    .bazis-item {
                        --padding-top: var(--bazis-padding-2x);
                        --padding-start: 0;

                        &::after {
                            display: none;
                        }

                        span {
                            top: 0;
                        }
                    }
                }
                h1 {
                    font-size: 28px;
                    line-height: 36px;
                }
                bazis-button {
                    display: block;
                }
            }
        }

        // 2.1 section
        &_education {
            .sl-section__content {
                --padding-top: 92px;
                text-align: center;

                .bazis-block {
                    --margin-bottom: 0;
                    --padding-bottom: var(--bazis-padding-6x);
                    --padding-start: var(--bazis-padding-4x);
                    --padding-end: var(--bazis-padding-4x);
                    // --margin-end: var(--bazis-margin-3x);
                    height: 100%;

                    &:hover {
                        bazis-button {
                            --background: url('/assets/images/video-btn.png') 50% 50%;
                            --color: var(--bazis-color-white);
                        }
                    }
                }

                h2 {
                    padding-bottom: calc(var(--bazis-padding-6x) * 2);
                    font-size: 40px;
                }
                h5 {
                    margin: 0 0 var(--bazis-margin-2x);
                    font-weight: 500;
                }

                .sl-video-btn {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 56px;
                    width: 56px;
                    margin: var(--bazis-margin-2x) auto var(--bazis-margin-6x);
                    cursor: pointer;

                    bazis-button {
                        --height: 56px;
                        --border-radius: 50%;
                        --background: rgba(var(--bazis-color-primary-rgb), 0.15);
                        --background-hover: url('/assets/images/video-btn.png') 50% 50%;
                        // --background-activated: rgba(var(--bazis-color-white-rgb), 0.6);
                        --color: rgba(var(--bazis-color-primary-rgb), 0.35);
                        --color-hover: var(--bazis-color-white);
                        width: 56px;
                        transition: background 200ms easy, color 200ms easy;

                        &::part(native) {
                            background-size: cover;
                        }

                        bazis-icon {
                            font-size: 24px;
                        }
                    }
                }

                .sl-grid_card {
                    margin: 0 calc(var(--bazis-margin-3x) * -1);

                    > bazis-row > bazis-col {
                        --bazis-grid-column-padding-start: var(--bazis-padding-3x);
                        --bazis-grid-column-padding-end: var(--bazis-padding-3x);
                    }
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    --padding-top: 88px;

                    h2 {
                        text-align: center;
                        padding-bottom: 40px;
                        br {
                            display: none;
                        }
                    }

                    h5 {
                        --bazis-font-size-h5: 18px;
                        --bazis-line-height-h5: 24px;
                    }

                    .bazis-block {
                        width: 302px;
                        height: auto;
                    }
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    h2 {
                        text-align: left;
                        font-size: 32px;
                    }
                    .bazis-block {
                        width: 100%;
                    }
                }
            }
        }

        // 2.2 section
        &_about {
            .sl-section__content {
                --padding-top: 92px;
                text-align: center;

                .bazis-block {
                    --margin-bottom: 0;
                    --padding-bottom: 40px;
                    height: 100%;
                }

                h2 {
                    padding-bottom: calc(var(--bazis-padding-6x) * 2);
                    font-size: 40px;
                }
                h5 {
                    margin: 0 0 var(--bazis-margin-2x);
                    font-weight: 500;
                }
                h6 {
                    font-weight: 400;
                    color: var(--bazis-text-color-secondary);
                }

                figure {
                    width: 165px;
                    margin: var(--bazis-margin-6x) auto var(--bazis-margin-8x);

                    img {
                        border-radius: 50%;
                    }
                }

                .sl-grid_card {
                    margin: 0 calc(var(--bazis-margin-3x) * -1);

                    > bazis-row > bazis-col {
                        --bazis-grid-column-padding-start: var(--bazis-padding-3x);
                        --bazis-grid-column-padding-end: var(--bazis-padding-3x);
                    }
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    --padding-top: 88px;
                    text-align: left;

                    h2 {
                        text-align: center;
                        padding-bottom: 40px;
                        br {
                            display: none;
                        }
                    }
                    h5 {
                        --bazis-font-size-h5: 18px;
                        --bazis-line-height-h5: 24px;
                    }

                    .bazis-block {
                        --padding-start: var(--bazis-padding-5x);
                        --padding-end: var(--bazis-padding-5x);
                        width: calc(50% - 6px);
                        height: auto;

                        &:last-child() {
                            margin-right: 0;
                        }
                    }
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-bottom: 88px;

                    h2 {
                        text-align: left;
                        font-size: 32px;
                    }
                    .bazis-block {
                        --padding-start: var(--bazis-padding-6x);
                        --padding-end: var(--bazis-padding-6x);
                        width: 100%;
                    }
                }
            }
        }

        // 3 section
        &_resolve {
            position: relative;
            overflow: hidden;
            background: url('/assets/images/bg-home-gal.svg') no-repeat calc(50% + 453px) -136px;

            .sl-section__content {
                --padding-top: 179px;
                --padding-bottom: 260px;
                position: relative;
                text-align: center;
            }

            h2 {
                max-width: 950px;
                margin: 0 auto var(--bazis-margin-8x);
            }

            h5 {
                max-width: 750px;
                margin: 0 auto calc(var(--bazis-margin-6x) * 2);
                font-weight: 400;
            }

            bazis-button {
                --height: 48px;
                font-size: 16px;
            }

            .sl-imgs-wrap {
                img {
                    position: absolute;
                    object-fit: cover;
                    border-radius: 8px;

                    &.sl-imgs {
                        &__1 {
                            top: 315px;
                            left: 46px;
                            width: 82px;
                            height: 82px;
                            transform: rotate(-30.7deg);
                        }
                        &__2 {
                            top: 135px;
                            left: 40px;
                            width: 50px;
                            height: 50px;
                            transform: rotate(-15.47deg);
                        }
                        &__3 {
                            top: 65px;
                            left: 218px;
                            width: 71px;
                            height: 71px;
                            transform: rotate(-8.58deg);
                        }
                        &__4 {
                            top: 70px;
                            right: 296px;
                            width: 58px;
                            height: 58px;
                            transform: rotate(18.47deg);
                        }
                        &__5 {
                            top: 79px;
                            right: 28px;
                            width: 84px;
                            height: 84px;
                            transform: rotate(27.63deg);
                        }
                        &__6 {
                            top: 290px;
                            right: 67px;
                            width: 53px;
                            height: 53px;
                            transform: rotate(22.62deg);
                        }
                    }
                }
            }

            @media (max-width: 1280px) {
                .sl-section__content {
                    position: static;
                }
            }

            @media (max-width: 1024px) {
                background: transparent;

                h5 {
                    --bazis-font-size-h5: 18px;
                    --bazis-line-height-h5: 24px;
                }

                .sl-imgs-wrap {
                    img.sl-imgs {
                        &__1 {
                            left: 65px;
                            transform: rotate(20deg);
                        }
                        &__2 {
                            left: 5px;
                        }
                        &__3 {
                            top: 45px;
                        }
                        &__5 {
                            right: -28px;
                        }
                    }
                }

                bazis-button {
                    width: 336px;
                    margin-bottom: var(--bazis-margin-3x);
                    margin-right: 0;

                    &:last-child {
                        --border-width: 1px;
                        --border-style: solid;
                        --border-color: var(--bazis-border-color-dark);
                        margin-bottom: 0;
                    }
                }
            }

            @media (max-width: 940px) {
                h2 {
                    br {
                        display: none;
                    }
                }

                .sl-imgs-wrap {
                    img.sl-imgs {
                        &__1 {
                            left: -34px;
                        }
                        &__2 {
                            left: -28px;
                        }
                        &__5 {
                            right: -47px;
                        }
                        &__6 {
                            right: -24px;
                        }
                    }
                }
            }

            @media (max-width: 660px) {
                .sl-section__content {
                    --padding-top: 344px;
                    --padding-bottom: 0;
                    text-align: left;
                }

                .sl-imgs-wrap {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;

                    img.sl-imgs {
                        &__1 {
                            top: 208px;
                            left: calc(50% - 88px / 2 - 116px);
                            transform: none;
                        }
                        &__2 {
                            top: 104px;
                            left: calc(50% - 64px / 2 - 156px);
                            transform: none;
                        }
                        &__3 {
                            top: 56px;
                            left: calc(50% - 96px / 2 + 36px);
                            transform: none;
                        }
                        &__4 {
                            top: 184px;
                            left: calc(50% - 48px / 2 - 16px);
                            right: auto;
                            transform: none;
                        }
                        &__5 {
                            top: 192px;
                            left: calc(50% - 72px / 2 + 112px);
                            right: auto;
                            transform: none;
                        }
                        &__6 {
                            top: 112px;
                            left: calc(50% - 56px / 2 + 160px);
                            right: auto;
                            transform: none;
                        }
                    }
                }

                bazis-button {
                    display: block;
                    width: 100%;
                }
            }

            @media (max-width: 576px) {
                background: var(--bazis-color-white);

                .bh-line-elements {
                    flex-direction: column;

                    bazis-button {
                        margin-bottom: 0;
                    }
                }
            }
        }

        // 4 section
        &_calculator {
            --margin-top: -135px;
            position: relative;

            .sl-section__content {
                .bazis-block {
                    --margin-bottom: 0;
                    --padding-top: calc(var(--bazis-padding-6x) * 2);
                    --padding-start: calc(var(--bazis-padding-6x) * 2);
                    --padding-end: calc(var(--bazis-padding-6x) * 2);

                    display: flex;
                    align-items: center;
                }

                .bazis-list {
                    --margin-bottom-list: var(--bazis-margin-4x);

                    & > .bazis-item {
                        --padding-top: calc(var(--bazis-padding-3x) * 3);
                        --padding-bottom: calc(var(--bazis-padding-3x) * 3);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        bazis-autocomplete,
                        input[type='range'],
                        bazis-range {
                            width: 320px;
                            flex-shrink: 0;
                        }
                    }
                }

                .sl-result {
                    font-size: 20px;
                    line-height: 22px;

                    &_no {
                        text-align: right;
                        margin-bottom: 40px;
                    }
                }
            }

            .sl-result-price {
                margin-bottom: 40px;
                text-align: right;
            }

            .sl-animation {
                position: relative;
                background: url('/assets/images/cover-anim.jpg') no-repeat 50% 50%;
                background-size: cover;
                width: 448px;
                height: 448px;
                margin-left: 72px;
                flex-shrink: 0;

                app-webgl-digger > div {
                    width: 448px;
                    height: 448px;
                }
            }

            h2 {
                font-size: 32px;
                line-height: 40px;
            }

            @media (max-width: 1280px) and (min-width: 1024.98px) {
                .sl-section__content {
                    .bazis-list > .bazis-item {
                        bazis-autocomplete,
                        input[type='range'],
                        bazis-range {
                            width: 288px;
                        }

                        &:last-child {
                            align-items: flex-end;
                            bazis-range {
                                margin-bottom: 6px;
                            }
                        }
                    }
                }
            }

            @media (max-width: 1150px) and (min-width: 1024.98px) {
                .sl-section__content {
                    .sl-animation {
                        width: 352px;
                        height: 352px;

                        app-webgl-digger > div {
                            width: 352px;
                            height: 352px;
                        }
                    }
                }
            }

            @media (max-width: 1130px) and (min-width: 1024.98px) {
                .sl-fieldset_standart {
                    display: block !important;
                }
                .sl-form > .bazis-list.bazis-list_underline {
                    display: none !important;
                }
            }

            @media (max-width: 1024px) {
                h2 {
                    text-align: center;
                    margin-bottom: 40px;
                }

                bazis-label {
                    --font-size: 14px;
                    --line-height: 20px;
                    font-weight: 400;
                }

                .sl-result-price {
                    margin-bottom: var(--bazis-margin-8x);
                    margin-top: var(--bazis-margin-8x);
                    padding-top: var(--bazis-padding-8x);
                    border-top: 1px dashed var(--bazis-border-color-dark);
                }

                hr {
                    --margin-bottom: var(--bazis-margin-5x);
                }
            }

            @media (max-width: 660px) {
                --margin-top: 104px;
            }

            @media (max-width: 576px) {
                --margin-top: 0;
                background: var(--bazis-color-white);
                overflow: hidden;

                .bazis-control {
                    --height: 34px;
                }

                h2 {
                    text-align: left;
                }

                .sl-section__content {
                    --padding-top: 72px;
                    --padding-bottom: 64px;

                    .bazis-block {
                        --padding-top: 0;
                        --padding-bottom: 0;
                        --padding-start: 0;
                        --padding-end: 0;
                        flex-direction: column-reverse;
                    }

                    .sl-animation {
                        display: block !important;
                        width: 352px;
                        height: 352px;
                        margin: 0 calc(var(--bazis-margin-5x) * -1);

                        app-webgl-digger > div {
                            width: 352px;
                            height: 352px;
                        }
                    }
                }
            }

            @media (max-width: 351px) {
                .sl-section__content {
                    .sl-animation {
                        width: 320px;
                        height: 320px;

                        app-webgl-digger > div {
                            width: 320px;
                            height: 320px;
                        }
                    }
                }
            }
        }

        // 5 section
        &_how-work {
            --margin-top: 104px;

            .sl-section__content {
                --padding-bottom: 120px;
                display: flex;
                flex-direction: row-reverse;
                align-items: flex-start;
                flex-wrap: wrap;

                &__description {
                    overflow: hidden;
                    flex-shrink: 0;
                    width: 384px;
                    margin-left: 72px;
                }
            }

            .sl-video {
                position: relative;
                flex-shrink: 0;
                // width: 824px;
                width: calc(100% - 384px - 72px);
                // height: 488px;
                // width: 66%;
                // height: 36.7%;

                border-radius: 12px;
                background: url('/assets/images/cover-video.jpg') no-repeat center center;
                background-size: cover;

                > div {
                    padding-top: 59.3023255%;
                }

                video {
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    // z-index: -100;
                    right: 0;
                    bottom: 0;
                    border-radius: 12px;
                    background: url('/assets/images/cover-video.jpg') no-repeat center center;
                    background-size: cover;
                    overflow: hidden;
                }

                &__btn {
                    box-sizing: content-box;
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    height: 100%;
                    width: 100%;
                    right: 0;
                    bottom: 0;
                    // border-radius: 12px;
                    // background: rgba(0, 162, 173, 0.3);
                    color: var(--bazis-color-white);
                    cursor: pointer;

                    bazis-button {
                        --height: 80px;
                        --border-radius: 50%;
                        --background: rgba(var(--bazis-color-white-rgb), 0.4);
                        --background-hover: rgba(var(--bazis-color-white-rgb), 0.2);
                        --background-activated: rgba(var(--bazis-color-white-rgb), 0.6);
                        --color: var(--bazis-color-white);
                        margin-top: 44px;
                        width: 80px;

                        bazis-icon {
                            font-size: 32px;
                        }
                    }

                    &:hover {
                        bazis-button {
                            --background: rgba(var(--bazis-color-white-rgb), 0.2);
                        }
                    }

                    // &.sl-video__btn_stop-pause {
                    //     background: transparent;

                    //     bazis-button {
                    //         --border-color-hover: var(--bazis-color-white);
                    //         --color-hover: var(--bazis-color-white);
                    //         opacity: 0;
                    //         margin-top: 0;

                    //         &:hover {
                    //             opacity: 1;
                    //     }
                    //     }
                    // }
                }
            }

            h2 {
                margin-top: 56px;
                margin-bottom: var(--bazis-margin-6x);
                font-size: 32px;
                line-height: 40px;
            }
            h5 {
                font-weight: 400;
            }

            @media (max-width: 1280px) and (min-width: 1024.98px) {
                .sl-section__content__description {
                    width: 384px;
                    margin-left: calc(var(--bazis-margin-6x) * 2);
                }
                .sl-video {
                    width: calc(100% - 384px - 48px);
                }

                h2 {
                    margin-top: var(--bazis-margin-6x);
                }
            }

            @media (max-width: 1024px) {
                h5 {
                    --bazis-font-size-h5: 18px;
                    --bazis-line-height-h5: 24px;
                }
            }

            @media (max-width: 1024px) and (min-width: 991.98px) {
                .sl-section__content {
                    --padding-bottom: 88px;

                    &__description {
                        width: 440px;
                        margin-left: 40px;
                    }
                }
                .sl-video {
                    width: calc(100% - 440px - 40px);
                }

                h2 {
                    margin-top: 0;
                }
            }

            @media (max-width: 992px) {
                .sl-section__content {
                    --padding-bottom: 80px;
                    display: block;

                    &__description {
                        width: 100%;
                        margin-left: 0;
                        margin-bottom: 40px;
                    }
                }

                .sl-video {
                    width: 100%;
                }

                h2 {
                    margin-top: 0;
                }
            }

            @media (max-width: 576px) {
                --margin-top: 64px;

                .sl-section__content {
                    --padding-bottom: 72px;

                    &__description {
                        margin-bottom: calc(var(--bazis-margin-6x) * 2);
                    }
                }

                .sl-video__btn bazis-button {
                    margin-top: 0;
                }
            }
        }

        // 6 section
        &_news {
            background-color: var(--bazis-background-dark);

            .sl-section__content {
                --padding-top: 88px;
                --padding-bottom: 75px;
                display: flex;
            }

            h2 {
                margin-top: var(--bazis-margin-8x);
                margin-bottom: var(--bazis-margin-5x);
                font-size: 32px;
                line-height: 40px;
            }
            h5 {
                font-weight: 500;
            }

            .sl-section__start {
                width: 401px;
                flex-shrink: 0;

                img {
                    margin-top: 73px;
                    margin-left: -35px;
                }
            }

            .sl-section__end {
                max-width: 776px;
                margin-left: 104px;

                .bazis-item {
                    --padding-top: var(--bazis-padding-8x);
                    --padding-bottom: var(--bazis-padding-8x);
                    overflow: hidden;

                    p {
                        margin-bottom: var(--bazis-margin-8x);
                    }
                }

                img {
                    float: right;
                    max-width: 160px;
                    max-height: 100px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    margin-left: calc(var(--bazis-margin-6x) * 2);
                    border-radius: var(--bazis-border-radius);
                    opacity: 0.9;
                }
            }

            @media (max-width: 1150px) and (min-width: 1024.98px) {
                .sl-section__end {
                    margin-left: var(--bazis-margin-8x);
                }
            }

            @media (max-width: 1024px) {
                background: var(--bazis-color-white);

                h2 {
                    text-align: center;
                    margin-bottom: 40px;
                }
                h5 {
                    --bazis-font-size-h5: 18px;
                    --bazis-line-height-h5: 24px;
                }

                .sl-section__content {
                    --padding-bottom: 88px;
                    display: block;
                }

                .sl-section__start {
                    width: auto;

                    img {
                        margin: 0;
                    }

                    .bazis-button-wrapper {
                        text-align: center;
                    }
                }

                .bazis-slider {
                    margin-bottom: 40px;

                    &.sl-slider-item {
                        width: 283px;
                        margin-right: var(--bazis-margin-4x);

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            max-width: 283px;
                            max-height: 160px;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                            border-radius: var(--bazis-border-radius);
                            opacity: 0.9;
                        }
                    }
                }
            }

            @media (max-width: 576px) {
                h2 {
                    text-align: left;
                }
            }
        }

        // 7 section
        &_faq {
            position: relative;

            .sl-section_faq__bg {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;

                background: url('/assets/images/home-faq-bg.jpg') 50% 0 no-repeat,
                    url('/assets/images/home-faq-repeat.jpg') 50% 0 repeat-y,
                    var(--bazis-background-dark);
                background-size: 1440px 813px, 1440px 1px;
            }

            .bazis-container_margin {
                position: relative;
            }

            .sl-section__content {
                --padding-top: 120px;
                --padding-bottom: 120px;
                --padding-start: 0;
                max-width: 650px;
            }

            h2 {
                margin-bottom: var(--bazis-margin-8x);
                font-size: 32px;
                line-height: 40px;
            }

            @media (min-width: 1435px) {
                .sl-section_faq__bg {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        right: calc(100% - (100% - 1360px) / 2 + 40px);
                        // right: calc(100% - 38px);
                        // right: calc(50% + var(--padding-side));
                        background: url('/assets/images/home-faq-repeat-left.jpg') 0 0 repeat-x;
                        background-size: 1px 813px;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        left: calc(100% - (100% - 1360px) / 2 + 40px);
                        // left: calc(100% - 38px);
                        // left: calc(50% + var(--padding-side));
                        background: url('/assets/images/home-faq-repeat-right.jpg') 0 0 repeat-x;
                        background-size: 1px 813px;
                    }
                }
            }

            @media (max-width: 1280px) and (min-width: 1025px) {
                .sl-section_faq__bg {
                    background: url('/assets/images/home-faq-bg.jpg') 50% 0 no-repeat;
                    background-size: cover;
                }
            }

            @media (max-width: 1024px) {
                .sl-section_faq__bg {
                    background: var(--bazis-background-medium);
                }

                .bazis-container_margin {
                    &::before,
                    &::after {
                        display: none;
                    }
                }

                .sl-section__content {
                    --padding-top: 88px;
                    --padding-bottom: 88px;
                    // --padding-start: 30px;
                    // --padding-end: calc(var(--bazis-padding-3x) * 3);
                    max-width: 100%;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 64px;
                    --padding-bottom: 64px;
                    --padding-start: 0;
                    --padding-end: 0;
                }
            }
        }
    }
}
