.bazis-tab-status {
    bazis-toolbar & {
        --margin-start: var(--bazis-margin-8x);
    }

    &__item {
        --color: var(--bazis-color-primary);
        --background-active: var(--bazis-color-primary);
        --color-active: var(--bazis-color-primary-contrast);
        --color-hover: var(--bazis-color-primary);

        bazis-button {
            // --height: 16px;
            --height-xs: 16px;
            --padding-top: 0;
            --padding-bottom: 0;
            --color: var(--bazis-color-action);
            font-size: 10px;
            letter-spacing: 1px;

            &.bazis-btn_has-icon-only {
                --padding-start: 0;
                --padding-end: 0;
            }

            &.bazis-btn--disabled {
                opacity: 0;
            }
        }

        &--active {
            bazis-button {
                --color: var(--bazis-color-action-contrast);
            }
        }

        &:hover {
            bazis-button {
                --color: var(--bazis-color-action);
            }
        }
    }
}
