.sl-dispatcher-page {
    height: 100vh;
    overflow: hidden;
    background: var(--bazis-border-color);

    .sl-header {
        padding-left: var(--bazis-padding-2x);
        padding-right: var(--bazis-padding-3x);

        &__left {
            .bazis-btn_outline {
                --border-radius: 50%;
                --background: rgba(var(--bazis-color-white-rgb), 0.1);
                --background-hover: rgba(var(--bazis-background-medium-rgb), 0.1);
                // --background-activated: ;
                // --background-disabled: ;
                --border-color: rgba(var(--bazis-background-info-rgb), 0.3);
                --border-color-hover: rgba(var(--bazis-background-info-rgb), 0.1);
                // --border-color-focused: ;
                // --border-color-disabled: ;
                --color: var(--bazis-color-white);
                // --color-disabled: ;
            }
        }
        &__title {
            padding: 10px var(--bazis-padding-4x);
            border-right: 1px solid rgba(224, 222, 220, 0.5);
            font-size: 16px;
            line-height: 16px;
            font-weight: 500;
        }

        .bazis-btn-group {
            margin: 0 var(--bazis-margin-6x); // right 32
        }

        .bazis-datetime-control {
            margin-bottom: 0;

            .bazis-control {
                --background: transparent;
                --border-width: 0;
                --color: var(--bazis-color-white);
                --font-size: 16px;
                width: 165px;
                cursor: pointer;

                &__field {
                    font-weight: 500;

                    &_decor {
                        // проверить в винде и на кросс браузерность
                        // position: absolute;
                        // z-index: 5;
                        // bottom: 5px;
                        // left: var(--padding-field-start);
                        padding-bottom: 2px;
                        border-bottom: 1px dashed var(--bazis-color-white);
                        font-size: 16px;
                        font-weight: 500;
                        pointer-events: none;
                    }

                    &-wrapper {
                        input {
                            opacity: 0;
                            pointer-events: none;
                        }
                    }

                    &-group {
                        bazis-icon {
                            margin: 0 -6px;
                            color: var(--bazis-color-white);
                        }

                        &__start {
                            .bazis-btn_clear {
                                --background-hover: rgba(var(--bazis-color-white-rgb), 0.1);
                                --background-activated: rgba(
                                    var(--bazis-background-medium-rgb),
                                    0.1
                                );
                                --background-focused: var(--background-hover);
                                --color: var(--bazis-color-white);
                            }
                        }
                    }
                }

                &__note {
                    display: none;
                }
            }
        }
    }

    .sl-dropdown_notifications .bazis-dropdown__body {
        transform: translateX(0);
    }

    .sl-footer {
        --background: var(--bazis-background);
        --color: var(--bazis-text-color);
        --padding-top: 0;
        --padding-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 32px;
        border-top: 1px solid var(--bazis-border-color);

        app-planning-page & {
            margin: 0 var(--bazis-margin-2x);
        }

        &__btn-group {
            bazis-button.bazis-btn_clear {
                --border-radius: 0;
                --color: var(--bazis-text-secondary);
                --color-hover: var(--bazis-color-tint);
                --color-activated: var(--bazis-color-base);
                border-right: 1px solid var(--bazis-border-color);
                width: 35px;
            }
        }

        .bazis-list_h {
            > .bazis-item {
                --margin-end: var(--bazis-margin-6x);
            }

            bazis-icon {
                --bazis-icon-xs-size: 12px;
                --bazis-icon-s-size: 16px;

                &:first-child:not(:nth-last-child(2)) {
                    margin-right: var(--bazis-margin-2x);
                }

                &:last-child {
                    margin-left: var(--bazis-margin-1x);
                }
            }

            .bazis-text_reduced {
                vertical-align: middle;
            }
        }

        .bazis-dropdown {
            --width: 250px;

            &__trigger {
                cursor: pointer;
            }
            &__body {
                --top: auto;
                --bottom: 20px;
            }
        }

        .sl-clock {
            span {
                display: inline-block;
                width: 125px;
            }
        }
    }

    .sl-dispatcher-settings {
        --padding-start: var(--bazis-padding-3x);
        --padding-end: var(--bazis-padding-3x);
        --padding-bottom: var(--bazis-padding-1x);

        p {
            margin: 0 0 var(--bazis-margin-4x);
            font-size: 13px;
            line-height: 20px;
            color: var(--bazis-text-color-secondary);
        }

        .bazis-control {
            --margin-bottom: var(--bazis-margin-4x);

            &_short {
                bazis-label {
                    --font-size: var(--font-size, inherit);
                    font-weight: 400;
                }
                .bazis-control__field-group {
                    max-width: 140px;
                }
            }
        }
    }

    &__content {
        height: calc(100% - 32px - 56px); // footer + header
        background: var(--bazis-background);
        border-radius: 3px 3px 0 0;

        app-planning-page & {
            height: calc(100% - 40px - 56px); // footer + header
            margin: var(--bazis-margin-2x) var(--bazis-margin-2x) 0;
        }

        .sl-dispatcher-settings {
            width: 236px;
        }

        .sl-btn-filter {
            --background: var(--background-hover);

            bazis-badge[slot='end'] {
                position: static;

                @media only screen and (-webkit-max-device-pixel-ratio: 1) {
                    line-height: var(--height-large);
                }
            }
        }
    }

    table {
        tr {
            &.sl-row_fix-height {
                --child-count: 1;

                td {
                    min-height: 59px;
                    height: calc(59px * var(--child-count));
                    overflow: hidden;
                    padding-top: 9px;
                    padding-bottom: 9px;
                }

                &.sl-row_fix-height_mini {
                    td {
                        min-height: 41px;
                        height: calc(41px * var(--child-count));
                    }
                }
            }
        }

        td,
        th {
            --padding-start: 6px;
            --padding-end: 6px;

            &:first-child {
                --padding-start: var(--bazis-padding-4x);
            }
        }

        th {
            --padding-bottom: var(--bazis-padding-1x); // если в таблице есть кнопка

            bazis-button {
                margin-top: calc(var(--bazis-margin-2x) * -1);
            }

            &:not(.sl-col_sortable) {
                bazis-button {
                    opacity: 0;
                }

                &:hover {
                    bazis-button {
                        opacity: 1;
                    }
                }
            }

            &.bh-sticky {
                --padding-bottom: 1.5px;
            }
        }

        td {
            --padding-top: var(--bazis-padding-3x);
            --padding-bottom: var(--bazis-padding-3x);

            bazis-badge.bazis-badge-large {
                --height-large: 20px;
                --font-size-large: 13px;
                --font-weight: 400;

                &:first-child:last-child {
                    --margin-start: 0;
                    --margin-end: 0;
                }
            }

            bazis-chip {
                --margin-start: 0;
                --margin-end: 0;
            }

            .sl-col_fix-height {
                min-height: 38px;
                max-height: 38px;
                overflow: hidden;
            }

            &.bh-sticky {
                --padding-start: 3px;
                --padding-end: 3px;
            }
        }

        app-task-table td {
            --padding-top: 6px;
            --padding-bottom: 6px;
        }

        .sl-row_danger td:first-child,
        .sl-row_warning td:first-child {
            position: relative;

            &::before {
                position: absolute;
                top: 13px;
                left: 0;
                content: '';
                padding: 0;
                width: 4px;
                height: 16px;
            }
        }
        .sl-row_danger td:first-child::before {
            background: var(--bazis-color-danger);
        }
        .sl-row_warning td:first-child::before {
            background: var(--bazis-color-warning);
        }

        .sl-row_dashed td {
            --border-style: dashed;
        }

        .sl-table__action-wrap {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            z-index: 1;
            display: flex;
            align-items: flex-start;
            background: linear-gradient(
                270deg,
                var(--bazis-color-white) 75%,
                rgba(var(--bazis-color-white-rgb), 0.8) 94.14%
            );
            box-shadow: -2px 2px 15px rgba(var(--bazis-shadow-color-rgb), 0.4);
            min-width: 40px;
            padding: var(--bazis-padding-1x) 3px 0 3px;

            bazis-button.bazis-color:hover {
                bazis-icon {
                    color: currentColor;
                }
            }

            bazis-button.sl-btn-filter {
                &.bazis-btn_clear {
                    --background: transparent;
                    --color: var(--bazis-text-color);
                }
                &.bazis-btn_solid bazis-icon {
                    color: var(--bazis-color-white);
                }
            }
        }

        app-position-table .sl-table__action-wrap {
            // width: 70px
        }
    }

    .bazis-pane--expanded {
        top: 121px;
        height: calc(100% - 153px);
        width: 50%;
    }

    .sl-planning-base {
        position: relative;
        height: calc(100% - 114px);

        .sl-settings-column {
            position: absolute;
            top: 0;
            right: 4px;
            z-index: 152;
            background: var(--bazis-background-medium);
        }
    }

    .sl-filter-applied-wrap {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: var(--bazis-padding-3x) var(--bazis-padding-4x) var(--bazis-padding-1x);
    }
}
