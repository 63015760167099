.bazis-dropdown {
    &.sl-dropdown_action {
        --top: 28px;
        --width: 210px;
        --color: var(--bazis-text-color-secondary);
        float: right;

        .bazis-list_select {
            border: var(--border-width-list) var(--border-style-list) var(--border-color-list);
        }
    }

    &.sl-dropdown_notifications {
        --width: 484px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;

        .bazis-dropdown__body {
            // transform: translateX(calc(var(--width) / 2 - 18px));
            display: none;

            &[data-show] {
                display: block;
            }
        }

        .bazis-dropdown__trigger bazis-badge {
            top: 2px;
            right: -2px;
        }

        @media (max-width: 576px) {
            --width: 100%;
            --border-radius: 0;
            position: static;

            .bazis-dropdown__body {
                top: 8px !important;
            }
        }
    }

    &.sl-dropdown_tooltip {
        bazis-tooltip {
            --min-width: 210px;
            --padding-top: var(--bazis-padding-3x);
            --padding-bottom: var(--bazis-padding-3x);
            --padding-start: 0;
            --padding-end: 0;
        }

        .bazis-item bazis-button {
            display: block;

            &::part(inner) {
                justify-content: flex-start;
            }
        }
    }

    &.sl-dropdown_settings-filter {
        --top: 36px;
        --left: 0;
        --width: 295px;
        --padding-top: var(--bazis-padding-3x);
        --padding-bottom: var(--bazis-padding-3x);

        bazis-button.bazis-dropdown__trigger {
            --background: var(--bazis-color-info-tint);
            --color: var(--bazis-color-base);
        }

        @media (max-width: 992px) {
            .bazis-dropdown__body {
                --top: auto;
                --left: 8px;
                --right: 8px;
                --bottom: 8px;
                --width: calc(100% - 16px);
                position: fixed;
                z-index: 1001;

                hr {
                    display: none;

                    + bazis-button {
                        --height: 44px;
                        display: block;
                    }
                }
            }
        }
    }

    &.sl-dropdown_download-group {
        --top: 44px;
        --width: 270px;
        --padding-top: var(--bazis-padding-3x);
        --padding-bottom: var(--bazis-padding-3x);

        .bazis-list_select {
            border: var(--border-width-list) var(--border-style-list) var(--border-color-list);
        }

        bazis-button.bazis-dropdown__trigger {
            --background: var(--bazis-color-info-tint);
            --color: var(--bazis-color-base);
        }
    }

    &.sl-dropdown_telemetry {
        bazis-button.bazis-dropdown__trigger {
            --color: var(--bazis-color-contrast);
        }

        bazis-thumbnail {
            margin-right: var(--bazis-margin-3x);
        }

        .bazis-dropdown__body {
            .bazis-item {
                &--disabled {
                    --background: var(--bazis-background-medium);
                    filter: grayscale(1);
                }
            }
            .bazis-list {
                .bazis-item {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }

    &.sl-dropdown_vehicle {
        --padding-bottom: var(--bazis-padding-3x);

        .bazis-dropdown__body {
            .bazis-list_control-dd {
                --margin-start-list: 0;
                --margin-end-list: 0;
                --margin-top-list: var(--bazis-margin-5x);
                --margin-bottom-list: 0;
            }

            hr {
                --margin-top: 0;
                --margin-bottom: var(--bazis-margin-3x);
                margin-left: -16px;
                margin-right: -16px;
            }

            .sl-close {
                position: absolute;
                right: 16px;
                top: 16px;
            }
        }
    }
}
