.sl-card-mobile {
    @include props-block-model;
    @include props-margin;
    @include props-colors;

    --background-hover: var(--background);
    --border-color-hover: var(--border-color);
    --box-shadow-hover: var(--box-shadow);

    --background-header: initial;
    --background-body: initial;

    @include block-model;
    @include margin;
    @include colors;

    &__header,
    &__body {
        @include props-block-model;
        @include props-margin;

        @include block-model;
        @include margin;
    }

    &__header {
        background: var(--background-header);

        bazis-toolbar {
            [slot='start'] {
                flex-direction: column;
                padding-top: 6px;
            }
        }

        bazis-status {
            display: inline-block;
            margin-top: var(--bazis-margin-2x);
        }
    }
    &__body {
        background: var(--background-body);

        bazis-input-options .bazis-control {
            display: flex;
            justify-content: space-between;
            align-items: center;

            bazis-label {
                --margin-bottom: 0;

                & + .bazis-checkbox:not(.bh-inline-block) {
                    margin-top: 0;
                }
            }

            .bazis-checkbox .bazis-checkbox__label::before {
                margin-right: 0;
            }
        }
    }

    &_hoverable {
        &:hover {
            background: var(--background-hover);
            border-color: var(--border-color-hover);
            box-shadow: var(--box-shadow-hover);
        }
    }
}

// implemented
.sl-card-mobile {
    --padding-top: var(--bazis-margin-2x);
    --border-width: 1px 1px 0;
    --border-style: solid;
    --border-color: var(--bazis-border-color);
    --border-color-hover: var(--bazis-border-color-dark);
    --background: var(--bazis-background-medium);
    --background-header: var(--bazis-color-white);
    --background-body: var(--bazis-color-white);

    &__header {
        --padding-top: var(--bazis-padding-1x);
        --padding-bottom: var(--bazis-padding-3x);
        --padding-start: var(--bazis-padding-3x);
        --padding-end: var(--bazis-padding-1x);
        --border-width: 1px 0 0;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
    }
    &__body {
        --padding-top: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-4x);
        --padding-start: var(--bazis-padding-3x);
        --padding-end: var(--bazis-padding-3x);

        bazis-input-options {
            display: block;
            margin-bottom: var(--bazis-margin-4x);

            &:last-child {
                margin-bottom: 0;
            }

            bazis-label {
                --font-size: 13px;
                --line-height: 16px;
                --color: var(--bazis-text-color-secondary);
            }
        }
    }

    &__selection {
        border-bottom: 1px solid var(--border-color);
        padding: 10px var(--bazis-padding-1x) 10px var(--bazis-padding-3x);
        margin: 0 calc(var(--bazis-margin-1x) * -1) 0 calc(var(--bazis-margin-3x) * -1);
    }

    &--expanded {
        .sl-card-mobile__header {
            --border-width: 1px 0;
        }
    }

    .sl-contacts-group & {
        --padding-top: 0;
        border-top: 0;
    }

    app-entity-item-mobile-skeleton:last-of-type &,
    &-item:last-of-type & {
        --border-width: 1px;
    }

    app-entity-item-mobile-skeleton:first-of-type &,
    &-item:first-of-type & {
        --border-width: 0 1px;
    }

    app-entity-item-mobile-skeleton:first-of-type &,
    &-item:first-of-type
        &:not(.sl-toolbar_table-header ~ * > .sl-card-mobile-item:first-of-type .sl-card-mobile) {
        --padding-top: 0;
    }

    &-item:first-of-type:last-of-type & {
        --border-width: 0 1px 1px;
    }

    dl.bazis-dl_easy {
        --margin-bottom-dd: var(--bazis-margin-4x);
        line-height: 16px;

        dt {
            font-weight: 500;
            line-height: 16px;
            color: var(--bazis-text-color-secondary);
        }

        + dl.bazis-dl_easy {
            --margin-top-dl: var(--bazis-margin-6x);
        }
    }
}
