bazis-label {
    .sl-grid_accordion & {
        --font-size: 12px;
        --line-height: 16px;
        --color: var(--bazis-text-color-secondary);
        font-weight: 400;
    }

    .sl-card-mobile-item & {
        --margin-bottom: var(--bazis-margin-2x);
        --font-size: 13px;
        --line-height: 16px;
        --color: var(--bazis-text-color-secondary);
    }

    .sl-form_oneline & {
        --margin-bottom: 0;
        --line-height: 36px;
    }

    .bazis-dropdown.sl-dropdown_settings-filter & {
        --margin-bottom: var(--bazis-margin-3x);

        & + .bazis-radio {
            margin-top: 0;
            margin-bottom: var(--bazis-margin-4x);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    app-lot-proposition .bazis-block & {
        --font-size: 13px;
        --color: var(--bazis-text-color-secondary);
        font-weight: 400;
    }

    .sl-filter__base & {
        @media (min-width: 992.98px) {
            --color: var(--bazis-text-color-secondary);
            --font-size: 16px;
        }
    }

    @media (max-width: 576px) {
        .sl-form_oneline & {
            --line-height: 20px;
        }
    }
}
