bazis-map {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

.bazis-map {
    height: 100%;
    width: 100%;

    &__base-control {
        position: absolute;
        z-index: 150; // из-за слоев leafleat
        top: 50%;
        right: var(--bazis-margin-5x);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transform: translateY(-50%);

        bazis-tooltip {
            --padding-top: var(--bazis-padding-1x);
            --padding-bottom: var(--bazis-padding-1x);
            --min-height: 24px;
            --min-width: auto;
        }

        .bazis-btn-group_h {
            bazis-button {
                --border-color: var(--bazis-border-color-dark);

                --background: var(--bazis-color-white);
                --color: var(--bazis-text-color-secondary);

                --background-activated: var(--bazis-color-info-tint);
                --color-activated: vvar(--bazis-text-color-secondary);

                --background-hover: var(--bazis-background-medium);
                --color-hover: var(--bazis-text-color-secondary);

                --background-focused: var(--background-hover);
                --color-focused: var(--color-hover);

                &:hover {
                    &::part(native) {
                        border-color: var(--border-color);
                    }

                    bazis-icon {
                        color: var(--color-hover);
                    }
                }
            }
        }

        & > * {
            margin-bottom: var(--bazis-margin-8x);

            &:last-child {
                margin-bottom: 0;
            }

            &:only-child {
                margin-bottom: 0;
            }
        }

        .bazis-figure & {
            top: 8px;
            right: 8px;
            transform: translateY(0);
        }

        // types
        &_top {
            top: 20px;
            transform: translateY(0);
        }

        // dropdown for controls on map (not hoverable)
        .bazis-dropdown {
            --width: 197px;
        }
    }

    &-wrapper {
        /*
        * @prop --height: Высота обертки, желательно в пикселях.
        *                 Без определенной высоты, компонент карты не будет понимать до чего расстягиваться
        * @prop --width: Ширина обертки
        * @prop --margin-start: внешний начальный отступ обертки
        * @prop --margin-end: внешний конечный отступ обертки
        * @prop --margin-top: внешний верхний отступ обертки
        * @prop --margin-bottom: внешний нижний отступ обертки
        */
        --height: 500px;
        --width: 100%;
        --margin-start: 0;
        --margin-end: 0;
        --margin-top: 0;
        --margin-bottom: 0;

        position: relative;
        height: var(--height);
        width: var(--width);
        margin: var(--margin-top) var(--margin-end) var(--margin-bottom) var(--margin-start);
    }

    &-circle {
        float: right;
        width: 180px;
        height: 180px;
        margin-left: var(--bazis-margin-3x);

        .bazis-map {
            clip-path: circle(90px);
        }
    }

    &.bazis-map_grayscale {
        .leaflet-tile-container img {
            filter: grayscale(1);
        }
    }

    &.bazis-map_fullscreen,
    &_fullscreen {
        position: fixed !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 10000;
        width: 100vw;
        height: 100vh;
        background: var(--bazis-background);
    }
}
